import React, { useEffect, useState } from 'react';
import { BalapanIconBlue } from './Icons';
import { useParams, Link } from 'react-router-dom';
import { BASE_URL } from './config';

const Promo4 = () => {
    const { code } = useParams();
    const [coupon, setCoupon] = useState(null);
    useEffect(() => {
        fetch(`${BASE_URL}/parties/admin-api/coupons/${code}`, { method: 'GET' })
          .then((response) => response.json())
          .then((data) => {
            setCoupon(data['response']);
          });
      }, [code]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-[#5fa9dd] p-4">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                <BalapanIconBlue className="w-auto h-24 mb-4 mx-auto" />
                {coupon && (
                    <>
                        <h1 className="text-3xl font-bold text-[#5fa9dd] mb-4 text-center">{coupon.client.name}</h1>
                        <div className="bg-white border-2 border-[#5fa9dd] rounded-lg p-2 flex items-stretch mb-4">
                            <div className="bg-[#5fa9dd] text-white font-bold py-2 px-3 rounded-l flex items-center justify-center">
                                КУПОН
                            </div>
                            <div className="flex-grow flex items-center justify-between px-3">
                                <span className="text-2xl font-bold">{coupon.code}</span>
                            </div>
                        </div>
                        <p className="text-xs text-gray-600 mb-4 text-center">Действительно до 25.10.2024</p>
                        <p className="text-sm text-gray-700 mb-6 text-center leading-relaxed">
                            Только для вас: купите <span className="font-bold">толстовку, кофту или штаны</span> всего за 
                            <span className="font-bold"> 499 сом</span> за единицу, или возьмите <span className="font-bold">2 единицы</span> всего за 
                            <span className="font-bold"> 490 сом</span> каждая!
                            Акция действует при предъявлении купона. Не упустите шанс!
                        </p>

                        <Link
                            to="/"
                            className="bg-[#DE74A8] hover:bg-[#DE6490] text-white font-bold py-2 px-4 rounded-full w-full transition duration-300 block text-center mb-4"
                        >
                            Получить 200 сом
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Promo4;
