import './App.css';
// import { LandingPage  } from './components/LandingPage';
import MobileAppShowcase  from './MobileAppShowcase';
import Promo from './Promo';
import Promo2 from './Promo2';
import Promo3 from './Promo3';
import Promo4 from './Promo4';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


function App() {
  const router = createBrowserRouter([
    {path: "/", element: <MobileAppShowcase />},
    {path: "p", children:[
      {path: ":code", element: <Promo />}
    ]},
    {path: "q", children:[
      {path: ":code", element: <Promo2 />}
    ]},
    {path: "s", children:[
      {path: ":code", element: <Promo3 />}
    ]},
    {path: "t", children:[
      {path: ":code", element: <Promo4 />}
    ]}
  ]);
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
