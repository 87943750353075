import React,  { useEffect, useState } from 'react';
import { BalapanIconBlue } from './Icons';
import { useParams, Link } from 'react-router-dom';
import { BASE_URL } from './config';

const Promo = () => {
    const { code } = useParams();
    const [coupon, setCoupon] = useState(null);
    useEffect(() => {
        fetch(`${BASE_URL}/parties/admin-api/coupons/${code}`, { method: 'GET'})
          .then((response) => response.json())
          .then((data) => {
            setCoupon(data['response']);
          });
      }, [code]);

    const turnInt = (float) => {
        return Math.round(float);
    };
    
    return (
        <div className="flex items-center justify-center min-h-screen bg-[#5fa9dd] p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <BalapanIconBlue className="w-auto h-24 mb-4" />
            <h3 className="text-lg font-bold text-[#5fa9dd] mb-4  text-center">Нам 8 лет!!!</h3>
            {coupon && <>
                <h1 className="text-3xl font-bold text-[#5fa9dd] mb-4 text-center">{coupon.client.name}</h1>
                <div className="bg-white border-2 border-[#5fa9dd] rounded-lg p-2 flex items-stretch mb-4">
                    <div className="bg-[#5fa9dd] text-white font-bold py-2 px-3 rounded-l flex items-center justify-center">
                        КУПОН
                    </div>
                    <div className="flex-grow flex items-center justify-between px-3">
                        <span className="text-2xl font-bold">{coupon.code}</span>
                    </div>
                </div>
                <p className="text-xs text-gray-600 mb-4 text-center">Действительно до 29.10.2024</p>
                <ul className="list-disc pl-5 mb-4 text-sm text-gray-700">
                <li>Действует во всех магазинах Balapan</li>
                <li>Скидки до 50% на зимние и осенние коллекции</li>
                <li>Различные другие мероприятия</li>
                <li>Получите 5% бонусный кэшбэк</li>
                <li>Бонусы можно использовать для оплаты до 50% от общей суммы чека</li>
                </ul>
                <h3 className="text-lg font-bold text-[#5fa9dd] mb-4  text-center">У вас бонусы в размере {turnInt(coupon.client.bonus_balance)} сомов </h3>
                
                <Link 
                    to="/" 
                    className="bg-[#DE74A8] hover:bg-[#DE74A8] text-white font-bold py-2 px-4 rounded-full w-full transition duration-300 mb-4 block text-center animate-pulseSize">
                    Скачать приложение
                </Link>
            </>}
        </div>
        </div>
    );
};

export default Promo;